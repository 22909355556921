<template>
  <VContainer class="pa-0">
    <VRow
      justify="space-between"
      align="center"
      no-gutters
    >
      <VCol cols="auto">
        <div
          class="tt-text-subtitle"
          data-test="tag-dialog-title"
        >
          Редактирование тега
        </div>
      </VCol>
      <VCol cols="auto">
        <TTBtn
          text
          min-width="auto"
          class="pa-0"
          data-test-label="tag-dialog-button-close"
          @click="handleClickCancel"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <VChip
          label
          color="tt-light-blue pale"
          class="tt-light-blue--text"
          data-test="tag"
        >
          <div dat-test="tag-name">
            {{ tag.name }}
          </div>
        </VChip>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <TTTextField
          v-model="editableItem.name"
          placeholder="Новое название тега"
          data-test-label="tag-dialog-input-name"
          large
        />
      </VCol>
    </VRow>
    <VRow
      justify="end"
    >
      <VCol cols="auto">
        <TTBtn
          color="tt-primary"
          text
          data-test-label="tag-dialog-button-cancel"
          @click="handleClickCancel"
        >
          Отменить
        </TTBtn>
      </VCol>
      <VCol cols="auto">
        <TTBtn
          color="tt-primary"
          data-test-label="tag-dialog-button-edit"
          @click="handleClickSubmit"
        >
          Редактировать
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'TagForm',
  props: {
    tag: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableItem: cloneDeep(this.tag),
    };
  },
  methods: {
    handleInput(value) {
      this.$emit('update:tag', value);
    },
    handleClickCancel() {
      this.$emit('click:cancel');
    },
    handleClickSubmit() {
      this.$emit('click:submit', this.editableItem);
    },
  },
};
</script>

<style scoped>

</style>
