<template>
  <VContainer>
    <VRow no-gutters>
      <VCol cols="8">
        <VContainer class="pa-0 position-sticky top-0 tt-light-mono-0">
          <VOverlay
            :value="overlay"
            absolute
          />
          <VRow align="center">
            <VCol>
              <h1
                class="tt-text-headline-1"
                data-test="tags-title"
              >
                Теги
              </h1>
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              <VForm
                ref="tagForm"
                v-model="isFormValid"
                @submit.prevent
              >
                <TTTextField
                  v-model.trim="tag"
                  large
                  clearable
                  :rules="tagRules"
                  data-test-label="tags-input"
                  placeholder="Название нового тега"
                  @blur="resetValidation"
                  @keydown.enter="handleInputTag"
                >
                  <template #append>
                    <span class="tt-light-mono-46--text">Enter</span>
                  </template>
                </TTTextField>
              </VForm>
            </VCol>
          </VRow>
        </VContainer>
        <template v-if="tags.length">
          <VScaleTransition
            tag="div"
            class="row row--dense mt-6"
            group
          >
            <VCol
              v-for="item in tags"
              :key="`tag-${item.id}`"
              cols="auto"
              class="tag-item"
            >
              <VChip
                :ref="`tag-${item.id}`"
                label
                color="tt-light-blue pale"
                class="tt-light-blue--text tag"
                data-test="tag"
              >
                <VRow
                  align="center"
                  dense
                >
                  <VCol cols="auto">
                    <div data-test="tag-name">
                      {{ item.name }}
                    </div>
                  </VCol>
                  <VCol cols="auto">
                    <MenuWrapper
                      :menu-list="generateTagMenu(item.id,item.name)"
                    >
                      <template #activator="{ on }">
                        <TTBtn
                          class="px-0"
                          min-width="20"
                          depressed
                          color="transparent"
                          data-test-label="tag-menu"
                          v-on="on"
                        >
                          <VIcon
                            size="20"
                            color="tt-light-blue"
                          >
                            fal fa-ellipsis-h
                          </VIcon>
                        </TTBtn>
                      </template>
                    </MenuWrapper>
                  </VCol>
                </VRow>
              </VChip>
            </VCol>
          </VScaleTransition>
        </template>
        <template v-else>
          <VRow>
            <VCol>
              <div
                class="tt-text-body-1 tt-light-mono-64--text"
                data-test="empty-tags-section"
              >
                Здесь появятся добавленные теги, которые можно будет назначать сотрудникам
              </div>
            </VCol>
          </VRow>
        </template>
      </VCol>
    </VRow>
    <DialogWrapper
      v-if="editTagDialog"
      v-model="editTagDialog"
      max-width="396"
    >
      <TagForm
        data-test="tag-dialog"
        :tag="editableItem"
        @click:cancel="handleClickCancel"
        @click:submit="handleClickSubmit"
      />
    </DialogWrapper>
    <DialogWrapper
      v-if="deleteTagDialog"
      v-model="deleteTagDialog"
      max-width="396"
      title="Удаление тега"
    >
      <TagDeleteForm
        :tag="editableItem"
        @click:cancel="handleClickCancel"
        @click:action="handleClickDelete"
      />
    </DialogWrapper>
    <VOverlay
      :value="overlay"
    />
  </VContainer>
</template>

<script>
import MenuWrapper from '@/components/shared/MenuWrapper.vue';
import TagForm from '@/components/forms/TagForm.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import TagDeleteForm from '@/components/forms/TagDeleteForm.vue';
import { mapState } from 'vuex';

export default {
  name: 'Tags',
  components: {
    TagDeleteForm,
    DialogWrapper,
    TagForm,
    MenuWrapper,
  },
  data() {
    return {
      isFormValid: true,
      tagRules: [
        (v) => !!v || 'Обязательное поле',
        (v) => (v && v.length >= 2) || 'Длина поля должна быть больше 2 символов',
        (v) => (v && !/[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(v))
          || 'Поле не должно содержать спец. символы',
        (v) => (v && !/\s/g.test(v)) || 'Поле не должно содержать пробелы',
      ],
      editTagDialog: false,
      deleteTagDialog: false,
      editableItem: {},
      tag: '',
      tags: [],
      overlay: false,
    };
  },
  computed: {
    ...mapState('customerInfo', ['config']),
    isTagAPIEnable() {
      return this.$di.ff.get('enable_api_tags');
    },
  },
  mounted() {
    if (this.isTagAPIEnable) {
      this.tagsGet();
    }
  },
  methods: {
    validateForm() {
      return this.$refs.tagForm.validate();
    },
    resetForm() {
      this.$refs.tagForm.reset();
    },
    resetValidation() {
      this.$refs.tagForm.resetValidation();
    },
    async tagsGet() {
      try {
        // eslint-disable-next-line no-unused-vars
        this.tags = await this.$repositories.tags.list({
          params: {
            /*
            *  TODO Убрать параметры customerId? Почему мы вообще должны указывать их внезапно?
            *  разве не из мидлвары они должны на беке браться?
            *  я же в таком случае могу просто у любого пользователя просматривать если вдруг на беке отсутствует
            *  проверка customerId в теле запроса и в токене
            * */
            customer_id: this.config.id,
          },
        });
      } catch (e) {
        // TODO Обработчик ошибок
        console.warn(e);
      }
    },
    async tagCreate(tagName) {
      try {
        await this.$repositories.tags.create({
          // TODO CustomerId - здесь такая же история как и выше описано
          data: {
            name: tagName,
            customer_id: this.config.id,
          },
        });
        await this.tagsGet();
      } catch (e) {
        this.$root.$emit('showNotifier', {
          title: 'Ошибка создания тега',
          msg: 'При создании тега произошла ошибка',
          timeout: 4000,
          type: 'error',
        });
      }
    },
    async tagDelete(tagId) {
      try {
        await this.$repositories.tags.delete({
          data: {
            id: tagId,
          },
        });
        await this.tagsGet();
      } catch (e) {
        // TODO Обработчик ошибок
        console.warn(e);
      }
    },
    async tagUpdate(tagId, updatePayload) {
      try {
        // eslint-disable-next-line no-unused-vars
        const { data } = await this.$repositories.tags.update({
          data: {
            id: tagId,
            ...updatePayload,
            customer_id: this.config.id,
          },
        });
        await this.tagsGet();
      } catch (e) {
        // TODO Обработчик ошибок
        console.warn(e);
      }
    },
    clearDialog() {
      this.editTagDialog = false;
      this.deleteTagDialog = false;
      this.editableItem = {};
    },
    handleClickCancel() {
      this.clearDialog();
    },
    handleClickSubmit(newItem) {
      const isSameTagExist = this.tags
        .find((item) => item.name.toLowerCase() === newItem.name.toLowerCase());
      // Если тег с таким именем существует
      if (typeof isSameTagExist !== 'undefined') {
        // и это не тот же самый тег, то показываем модалку на объединение
        if (isSameTagExist.id !== newItem.id) {
          // TODO Вернуть когда появится логика объединения
          // const confirmationUnionAction = () => {
          //   this.editTagDialog = false;
          //   this.editableItem = {};
          //   //  TODO Здесь нужно описать логику по объединению тегов, но как если ручки не работают?
          // };
          // this.$root.$emit('showConfirmation', {
          //   title: 'Дублирование названия тега',
          //   description: `Тег «${isSameTagExist.name}» уже существует.
          // Объединить тег «${this.editableItem.name}» с тегом «${newItem.name}»`,
          //   confirmAction: confirmationUnionAction,
          //   confirmButtonColor: 'tt-primary',
          //   confirmButtonText: 'Объединить',
          // });

          this.$root.$emit('showConfirmation', {
            title: 'Дублирование названия тега',
            description: `Тег «${isSameTagExist.name}» уже существует.`,
          });
        } else { //  Если это тот же самый тег и название такое же, просто закрываем диалог
          this.clearDialog();
        }
      } else { // Если тега с таким названием не существует, то меняем название на новое
        this.handleUpdateTag(newItem);
        this.clearDialog();
      }
    },
    async handleDelete(itemId) {
      try {
        if (this.isTagAPIEnable) {
          await this.tagDelete(itemId);
          await this.tagsGet();
        } else {
          const deleteIndex = this.tags.findIndex((item) => item.id === itemId);
          if (deleteIndex !== -1) {
            this.tags.splice(deleteIndex, 1);
          }
        }
      } catch (e) {
        // TODO Обработчик ошибок
        console.warn(e);
      }
    },
    async handleUpdateTag(tag) {
      try {
        const updateTagIndex = this.tags.findIndex((item) => item.id === tag.id);
        // Если тек нашли, то обноваляем название и скролим к тегу
        if (updateTagIndex !== -1) {
          if (this.isTagAPIEnable) {
            const updatePayload = { name: tag.name };
            await this.tagUpdate(tag.id, updatePayload);
          }
          this.useFlashEffectAnimation(this.$refs[`tag-${tag.id}`][0]);
        } else {
          this.$root.$emit('showNotifier', {
            title: 'Ошибка редактирования тега',
            msg: 'Произошла ошибка, попробуйте еще раз',
            timeout: 3000,
            type: 'error',
          });
        }
      } catch (e) {
        // TODO Обработчик ошибок
        console.warn(e);
      }
    },
    handleClickDelete() {
      this.handleDelete(this.editableItem.id);
      this.deleteTagDialog = false;
    },
    generateTagMenu(id) {
      const editAction = async () => {
        this.editTagDialog = true;
        this.editableItem = this.tags.find((item) => item.id === id);
      };
      const menuDeleteAction = () => {
        this.deleteTagDialog = true;
        this.editableItem = this.tags.find((item) => item.id === id);
      };
      return [
        {
          icon: 'fal fa-pen',
          color: '',
          text: 'Редактировать',
          dataTest: 'menu-tag-open-edit-form',
          action: editAction,
        }, {
          icon: 'fal fa-trash-alt',
          color: 'tt-light-red',
          text: 'Удалить',
          dataTest: 'menu-tag-open-delete-form',
          action: menuDeleteAction,
        },
      ];
    },
    async handleInputTag() {
      const isValidated = this.validateForm();
      if (!isValidated) {
        return;
      }
      const isTagExist = this.tags.find((item) => item.name.toLowerCase() === this.tag.toLowerCase());
      if (typeof isTagExist !== 'undefined') {
        this.$root.$emit('showNotifier', {
          title: 'Ошибка создания тега',
          msg: `Тег "${this.tag}" уже существует`,
          timeout: 4000,
          type: 'error',
        });
        this.$vuetify.goTo(this.$refs[`tag-${isTagExist.id}`][0], { offset: 300 });
        this.useFlashEffectAnimation(this.$refs[`tag-${isTagExist.id}`][0]);
        this.useOverlayEffect();
      } else {
        await this.tagCreate(this.tag);
        this.resetValidation();
        this.resetForm();
      }
    },
    useFlashEffectAnimation(refElement) {
      refElement.$el.classList.add('flash-effect');
      setTimeout(() => {
        refElement.$el.classList.remove('flash-effect');
      }, 3000);
    },
    useOverlayEffect() {
      this.overlay = true;
      setTimeout(() => {
        this.overlay = false;
      }, 3000);
    },
  },
};
</script>

<style scoped lang="scss">
.tag {
  position: relative;
  z-index: 1;
}

.flash-effect {
  animation-name: flash;
  animation-duration: .3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  z-index: 6;
}

@keyframes flash {
  from {
    opacity: 1; /* Непрозрачный текст */
  }
  to {
    opacity: .5; /* Прозрачный текст */
  }
}

</style>
