<template>
  <VContainer class="pa-0">
    <VRow
      justify="space-between"
      align="center"
      no-gutters
    >
      <VCol cols="auto">
        <div
          class="tt-text-subtitle"
          data-test="tag-delete-dialog-title"
        >
          Удаление тега
        </div>
      </VCol>
      <VCol cols="auto">
        <TTBtn
          text
          min-width="auto"
          class="pa-0"
          data-test-label="tag-delete-dialog-button-close"
          @click="handleClickCancel"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol>
        <VChip
          label
          color="tt-light-blue pale"
          class="tt-light-blue--text"
          data-test="tag"
        >
          {{ tag.name }}
        </VChip>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol>
        <div data-test="tag-delete-dialog-description">
          При удалении этот тег будет снят со всех сотрудников
        </div>
      </VCol>
    </VRow>
    <VRow
      justify="end"
    >
      <VCol cols="auto">
        <TTBtn
          color="tt-primary"
          text
          data-test-label="tag-delete-dialog-button-cancel"
          @click="handleClickCancel"
        >
          Отменить
        </TTBtn>
      </VCol>
      <VCol cols="auto">
        <TTBtn
          color="tt-danger"
          data-test-label="tag-delete-dialog-button-action"
          @click="handleClickAction"
        >
          Удалить
        </TTBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

export default {
  name: 'TagDeleteForm',
  props: {
    tag: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClickCancel() {
      this.$emit('click:cancel');
    },
    handleClickAction() {
      this.$emit('click:action', this.editableItem);
    },
  },
};
</script>

<style scoped>

</style>
